import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    checkStatus,
    composeAuth,
    AuthError,
    getById,
} from "../../helpers/utils";
import querystring from "query-string";
import { logoutUser } from "../actions/account";

export const getBookingSchedule = createAsyncThunk(
    "bookings/getBookingSchedule",
    async (payload, thunkApi) => {
        try {
            const Authorization = composeAuth(payload?.jwt);
            const url =
                process.env.REACT_APP_SERVER +
                `/api/bookings/templates/${payload?.id}`;
            const res = await fetch(url, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization,
                },
            });

            const response = await res.json();
            const validateData = checkStatus(response);
            return validateData?.data;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const getBookingSlotsByDateandModuleId = createAsyncThunk(
    "bookings/getBookingSlotsByDateandModuleId",
    async (payload, thunkApi) => {
        try {
            const Authorization = composeAuth(payload?.jwt);
            const url =
                process.env.REACT_APP_SERVER +
                    (payload?.isPublic ? `/api/bookings/slots/module/${payload?.moduleId}/date/${payload?.date}/public` : `/api/bookings/slots/module/${payload?.moduleId}/date/${payload?.date}`);
            const res = await fetch(url, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization,
                },
            });

            const response = await res.json();
            const validateData = checkStatus(response);
            return validateData?.data;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const getBookingsByModuleId = createAsyncThunk(
    "bookings/getBookingsByModuleId",
    async (payload, thunkApi) => {
        try {
            const Authorization = composeAuth(payload?.jwt);
            const url =
                process.env.REACT_APP_SERVER +
                `/api/bookings/slots/module/${payload?.id}`;
            const res = await fetch(url, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization,
                },
            });

            const response = await res.json();
            const validateData = checkStatus(response);
            return validateData?.data;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const getMyBookingsByModuleId = createAsyncThunk(
    "bookings/getMyBookingsByModuleId",
    async (payload, thunkApi) => {
        try {
            const Authorization = composeAuth(payload?.jwt);
            const url =
                process.env.REACT_APP_SERVER +
                `/api/bookings/slots/module/${payload?.id}/me/multi`;
            const res = await fetch(url, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization,
                },
            });

            const response = await res.json();
            const validateData = checkStatus(response);
            return validateData?.data;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const getMyBookings = createAsyncThunk(
    "bookings/getMyBookings",
    async (payload, thunkApi) => {
        try {
            const Authorization = composeAuth(payload?.jwt);
            const url =
                process.env.REACT_APP_SERVER +
                `/api/bookings/slots/module/me/multi`;
            const res = await fetch(url, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization,
                },
            });

            const response = await res.json();
            const validateData = checkStatus(response);
            return validateData?.data;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const generateBookingSlots = createAsyncThunk(
    "bookings/generateBookingSlots",
    async (payload, thunkApi) => {
        try {
            const Authorization = composeAuth(payload?.jwt);
            const url = process.env.REACT_APP_SERVER + `/api/bookings/slots`;
            const res = await fetch(url, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization,
                },
                body: JSON.stringify(payload?.data),
            });

            const response = await res.json();
            const validateData = checkStatus(response);
            return validateData?.data;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const createBookingSlots = createAsyncThunk(
    "bookings/createBookingSlots",
    async (payload, thunkApi) => {
        try {
            const Authorization = composeAuth(payload?.jwt);
            const url = process.env.REACT_APP_SERVER + `/api/bookings/templates`;
            const res = await fetch(url, {
                method: "PUT",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization,
                },
                body: JSON.stringify(payload?.data),
            });

            const response = await res.json();
            const validateData = checkStatus(response);
            return validateData?.data;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const delBookingSchedule = createAsyncThunk(
    "bookings/delBookingSchedule",
    async (payload, thunkApi) => {
        try {
            const Authorization = composeAuth(payload.jwt);
            const url =
                process.env.REACT_APP_SERVER + `/api/bookings/templates/${payload?.id}`;
            const res = await fetch(url, {
                method: "DELETE",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization,
                },
            });

            const response = await res.json();
            const validateData = checkStatus(response);
            return validateData?.data;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);

export const bookSlots = createAsyncThunk(
    "bookings/bookSlots",
    async (payload, thunkApi) => {
        try {
            const Authorization = composeAuth(payload?.jwt);
            const url = process.env.REACT_APP_SERVER + (payload?.isPublic ? `/api/bookings/slots/institution/${payload?.institutionId}/book/ids/public/multi?${querystring.stringify({
                ids: payload?.slotIds,
            })}` : (payload?.isUser ? `/api/bookings/slots/institution/${payload?.institutionId}/book/me/ids/multi?${querystring.stringify({
                ids: payload?.slotIds,
            })}` : `/api/bookings/slots/institution/${payload?.institutionId}/book/ids/multi?${querystring.stringify({
                ids: payload?.slotIds,
            })}`));
            const res = await fetch(url, {
                method: "PATCH",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization,
                },
                body: JSON.stringify(payload?.data),
            });

            const response = await res.json();

            const validateData = checkStatus(response);
            return validateData?.data;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);



export const getBookingTemplateByModuleId = createAsyncThunk(
    "bookings/getBookingTemplateByModuleId",
    async (payload, thunkApi) => {
        try {
            const Authorization = composeAuth(payload?.jwt);
            const url = process.env.REACT_APP_SERVER + `/api/bookings/templates/module/${payload?.moduleId}`;
            const res = await fetch(url, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization,
                },
            });

            const response = await res.json();
            const validateData = checkStatus(response);
            return validateData?.data;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);


export const cancelSlots = createAsyncThunk(
    "bookings/cancelSlots",
    async (payload, thunkApi) => {
        try {
            const Authorization = composeAuth(payload?.jwt);
            const url = process.env.REACT_APP_SERVER + (payload?.isUser ? `/api/bookings/slots/institution/${payload?.institutionId}/cancel/ids/multi?${querystring.stringify({
                ids: payload?.ids,
            })}` : `/api/bookings/slots/institution/${payload?.institutionId}/profile/${payload?.profileId}/cancel/ids/multi?${querystring.stringify({
                ids: payload?.ids,
            })}`)
            const res = await fetch(url, {
                method: "PATCH",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization,
                },
                body: JSON.stringify(payload?.data),
            });

            const response = await res.json();
            const validateData = checkStatus(response);
            return validateData?.data;
        } catch (err) {
            if (err instanceof AuthError) {
                thunkApi.dispatch(logoutUser());
            }
            return thunkApi.rejectWithValue(err);
        }
    }
);
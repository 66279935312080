import { createSlice } from "@reduxjs/toolkit";
import {
  addEvent,
  addEventMultiple,
  getAllEvents,
  getAllEventsByTagId,
  editEvent,
  delEvent,
  editMultipleEvents,
  checkEventsConflict
} from "../actions/events";
import { logoutUser } from "../actions/account";

const initialState = {
  error: false,
  isLoadingAddEvent: false,
  isLoadingGetEvents: false,
  isLoadingEditevent: false,
  isLoadingDelEvent: false,
  isLoadingMultipleEvents: false,
  eventsList: [],
  eventDetails:{},
  offlineStatus: false,
  allGoalStatus: false,
  persistedLocalEvents: [],
  persistedLocalGoals: [],
  showAllGoalStatus: false,
  persistedCalenderView: "dayGridMonth",
  persistedProfiles: [],
  persistedLocation: [],
  persistedProduct: [],
  persistedCategories: [],
  persistedGoalType: [],
  isLoadingCheckEventsConflict: false,
  conflictObj: {},
  calDate:null
};

const eventSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    "account/logoutUser": (state) => initialState,
    storeOfflineStatus(state, action) {
      state.offlineStatus = action.payload;
    },
    storeCalendarDate(state, action) {
      state.calDate = action.payload;
    },
    storeAllGoalsStatus(state, action) {
      state.allGoalStatus = action.payload;
    },
    showAllGoalStatus(state, action) {
      state.showAllGoalStatus = action.payload;
    },
    storeLocalEvents(state, action) {
      state.persistedLocalEvents = action.payload;
    },
    storeLocalGoals(state, action) {
      state.persistedLocalGoals = action.payload;
    },
    storeCalenderView(state, action) {
      state.persistedCalenderView = action.payload;
    },
    storeSelectedProfiles(state, action) {
      state.persistedProfiles = action.payload;
    },
    storeSelectedLocations(state, action) {
      state.persistedLocation = action?.payload;
    },
    storeSelectedProducts(state, action) {
      state.persistedProduct = action?.payload;
    },
    storeSelectedCategories(state, action) {
      state.persistedCategories = action?.payload;
    },
    storeSelectedGoalType(state, action) {
      state.persistedGoalType = action?.payload;
    }
  },
  extraReducers: {
    [addEvent.pending]: (state, action) => {
      state.isLoadingAddEvent = true;
      state.eventDetails = action?.payload?.data?.event;
      state.error = false;
    },
    [addEvent.fulfilled]: (state, action) => {
      state.isLoadingAddEvent = false;
      state.error = false;
    },
    [addEvent.rejected]: (state, action) => {
      state.isLoadingAddEvent = false;
      state.error = true;
    },
    [addEventMultiple.pending]: (state, action) => {
      state.isLoadingAddEvent = true;
      state.eventDetails = action?.payload;
      state.error = false;
    },
    [addEventMultiple.fulfilled]: (state, action) => {
      state.isLoadingAddEvent = false;
      state.error = false;
    },
    [addEventMultiple.rejected]: (state, action) => {
      state.isLoadingAddEvent = false;
      state.error = true;
    },
    [getAllEvents.pending]: (state, action) => {
      state.isLoadingGetEvents = true;
      state.error = false;
    },
    [getAllEvents.fulfilled]: (state, action) => {
      state.isLoadingGetEvents = false;
      state.eventsList = action?.payload?.data?.events;
      state.error = false;
    },
    [getAllEvents.rejected]: (state, action) => {
      state.isLoadingGetEvents = false;
      state.error = true;
    },
    [getAllEventsByTagId.pending]: (state, action) => {
      state.isLoadingGetEvents = true;
      state.error = false;
    },
    [getAllEventsByTagId.fulfilled]: (state, action) => {
      state.isLoadingGetEvents = false;
      state.eventsList = action?.payload?.data?.event;
      state.error = false;
    },
    [getAllEventsByTagId.rejected]: (state, action) => {
      state.isLoadingGetEvents = false;
      state.error = true;
    },
    [editEvent.pending]: (state, action) => {
      state.isLoadingEditevent = true;
      state.error = false;
    },
    [editEvent.fulfilled]: (state, action) => {
      const temp = action?.payload?.data?.event;
      state.eventsList = state.eventsList?.map(
        (obj) => [temp].find((o) => o._id === obj._id) || obj
      );
      state.isLoadingEditevent = false;
      state.error = false;
    },
    [editEvent.rejected]: (state, action) => {
      state.isLoadingEditevent = false;
      state.error = true;
    },
    [delEvent.pending]: (state, action) => {
      state.isLoadingDelEvent = true;
      state.error = false;
    },
    [delEvent.fulfilled]: (state, action) => {
      state.isLoadingDelEvent = false;
      state.error = false;
    },
    [delEvent.rejected]: (state, action) => {
      state.isLoadingDelEvent = false;
      state.error = true;
    },
    [editMultipleEvents.pending]: (state, action) => {
      state.isLoadingMultipleEvents = true;
      state.error = false;
    },
    [editMultipleEvents.fulfilled]: (state, action) => {
      state.isLoadingMultipleEvents = false;
      state.error = false;
    },
    [editMultipleEvents.rejected]: (state, action) => {
      state.isLoadingMultipleEvents = false;
      state.error = true;
    },
    [checkEventsConflict.pending]: (state, action) => {
      state.isLoadingCheckEventsConflict = true;
      state.error = false;
    },
    [checkEventsConflict.fulfilled]: (state, action) => {
      state.isLoadingCheckEventsConflict = false;
      state.conflictObj = action?.payload?.data
      state.error = false;
    },
    [checkEventsConflict.rejected]: (state, action) => {
      state.isLoadingCheckEventsConflict = false;
      state.error = true;
    },
    [logoutUser.fulfilled]: (state) => {
      return initialState;
    },
    [logoutUser.rejected]: (state) => {
      return initialState;
    },
  },
});

export const getError = (state) => state.events.error;
export const getIsLoadingAddEvent = (state) => state.events.isLoadingAddEvent;
export const getEventDetails = (state) => state.events.eventDetails;
export const getIsLoadingGetEvents = (state) => state.events.isLoadingGetEvents;
export const getIsLoadingEditEvent = (state) => state.events.isLoadingEditevent;
export const getIsLoadingMultipleEvents = (state) =>
  state.events.isLoadingMultipleEvents;
export const getIsLoadingDelEvent = (state) => state.events.isLoadingDelEvent;
export const getEventList = (state) => state.events.eventsList;
export const getOfflineStatus = (state) => state.events.offlineStatus;
export const getPersistedLocalEvents = (state) =>
  state.events.persistedLocalEvents;
export const getPersistedLocalGoals = (state) =>
  state.events.persistedLocalGoals;
export const getAllGoalStatus = (state) => state.events.allGoalStatus;
export const getShowAllGoalStatus = (state) => state.events.showAllGoalStatus;
export const getCalenderView = (state) => state.events.persistedCalenderView
export const getPersistedProfileForFilters = (state) => state.events.persistedProfiles;
export const getPersistedLocationForFilters = (state) => state.events.persistedLocation;
export const getPersistedProductForFilters = (state) => state.events.persistedProduct;
export const getPersistedCategoriesFroFilters = (state) => state.events.persistedCategories;
export const getPersistedGoalTypeForFilter = (state) => state.events.persistedGoalType;
export const getIsLoadingCheckEventsConflict = (state) => state.events.isLoadingCheckEventsConflict;
export const getConflictObj = (state) => state.events.conflictObj;
export const getCalendarDate = (state) => state.events.calDate;

export const {
  storeOfflineStatus,
  storeLocalEvents,
  storeLocalGoals,
  storeAllGoalsStatus,
  showAllGoalStatus,
  storeCalenderView,
  storeSelectedProfiles,
  storeSelectedLocations,
  storeSelectedProducts,
  storeSelectedCategories,
  storeSelectedGoalType,
  storeCalendarDate
} = eventSlice.actions;

export default eventSlice;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { checkStatus, AuthError, composeAuth } from "../../helpers/utils";
import { logoutUser } from "../actions/account";

export const getAllEvents = createAsyncThunk(
  "events/getAllEvents",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload?.jwt);
      const url = process.env.REACT_APP_SERVER + (payload?.isUser ? `/api/events/me/institution/${payload?.institutionId}` : `/api/events/institution/${payload?.institutionId}`);
      const res = await fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
      });

      const response = await res.json();

      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const getAllEventsByTagId = createAsyncThunk(
  "events/getAllEventsByTagId",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload?.jwt);
      const url = process.env.REACT_APP_SERVER + (payload?.isUser ? `/api/events/me/tag/${payload?.tagId}/institution/${payload?.institutionId}` : `/api/events/tag/${payload?.tagId}/institution/${payload?.institutionId}`);
      const res = await fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
      });

      const response = await res.json();

      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const addEvent = createAsyncThunk(
  "events/addEvent",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload?.jwt);
      const url = process.env.REACT_APP_SERVER + `/api/events`;
      const res = await fetch(url, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
        body: JSON.stringify(payload?.requestBody),
      });

      const response = await res.json();

      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const addEventMultiple = createAsyncThunk(
  "events/addEventMultiple",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload?.jwt);
      const url = process.env.REACT_APP_SERVER + `/api/events`;
      let resArray = []
      for (const item of payload?.requestBody) {
        const res = await fetch(url, {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Authorization,
          },
          body: JSON.stringify(item),
        });
        const response = await res.json();
        const validateData = checkStatus(response);
        resArray?.push(validateData)
      }
      return resArray;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);


export const editEvent = createAsyncThunk(
  "events/editEvent",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload?.jwt);
      const url = process.env.REACT_APP_SERVER + `/api/events/${payload?.id}`;
      const res = await fetch(url, {
        method: "PATCH",
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
        body: JSON.stringify(payload?.requestBody),
      });

      const response = await res.json();

      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);


export const delEvent = createAsyncThunk(
  "events/delEvent",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload?.jwt);
      const url = process.env.REACT_APP_SERVER + `/api/events/${payload?.id}`;
      const res = await fetch(url, {
        method: "DELETE",
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
      });

      const response = await res.json();

      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const editMultipleEvents = createAsyncThunk(
  "events/editMultipleEvents",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload?.jwt);
      let resArray = []
      for (let item of payload?.requestBody?.events) {
        const url = process.env.REACT_APP_SERVER + `/api/events/${item?._id}`;
        const res = await fetch(url, {
          method: "PATCH",
          headers: {
            "Content-type": "application/json",
            Authorization,
          },
          body: JSON.stringify({ ...item, institutionId:payload?.institutionId }),
        });
        const response = await res.json();
        const validateData = checkStatus(response);
        resArray?.push(validateData)
      }
      return resArray;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const checkEventsConflict = createAsyncThunk(
  "events/checkEventsConflict",
  async (payload, thunkApi) => {
    try {
      const Authorization = composeAuth(payload?.jwt);
      const url = process.env.REACT_APP_SERVER + `/api/events/conflict`;
      const res = await fetch(url, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization,
        },
        body: JSON.stringify(payload?.requestBody),
      });

      const response = await res.json();

      const validateData = checkStatus(response);
      return validateData;
    } catch (err) {
      if (err instanceof AuthError) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(err);
    }
  }
);
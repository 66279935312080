import { useEffect, useRef } from "react";
import customError from "custom-error";
import * as json2csv from "json2csv";
import _ from "underscore";
import moment from "moment";
import numeral from 'numeral';

export const AuthError = customError("AuthError");

export const useInterval = (callback, delay) => {
  const savedCallback = useRef(callback)

  // Remember the latest callback if it changes.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    // Note: 0 is a valid value for delay.
    if (!delay && delay !== 0) {
      return
    }

    const id = setInterval(() => savedCallback.current(), delay)

    return () => clearInterval(id)
  }, [delay])
}

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const getCommentsSubmitMessage = (privateStatus, userView, profileType) => {
  if (privateStatus) {
    return "This comment will only be visible to you"
  }
  else {
    if (userView) {
      return "This comment will be visible to all users"
    }
    else {
      return profileType === "admin" ? "This comment will only be visible to admins" : "This comment will  be visible to all users"
    }
  }
};

export const convertJSONToCSV = (data, fields) => {
  try {
    let csv = json2csv.parse(data, { fields });
    return csv;
  } catch (err) {
    return null;
  }
};

export const getAllowedFields = (userType, fields, userEditTags) => {
  let finalFields = []
  if (userType === "admin") {
    if (userEditTags && userEditTags?.length) {
      finalFields = fields?.filter((item) => {
        const finalStatus = _.intersection(item?.edit_tag, userEditTags)?.length > 0
        return finalStatus
      })
    }
    else {
      finalFields = fields
    }
  }
  else if (userType !== "admin") {
    finalFields = fields?.filter((item) => {
      const finalStatus = _.intersection(item?.edit_tag, userEditTags)?.length > 0
      return finalStatus
    })
  }
  return finalFields
};

export const checkStatus = (response) => {
  if (response.statusCode >= 200 && response.statusCode < 300) return response;

  if (response.statusCode == 401 && response.statusCode !== "") {
    let error = new AuthError();
    error.message = response.message;
    throw error;
  } else {
    let error = new Error();
    error.message = response.message;
    throw error;
  }
};

export const parseJSON = (response) => response.json();

export const composeAuth = (jwt) => "Bearer " + jwt;

export const getById = (data, field = "id") => {
  let byId = {};
  data.forEach((s) => {
    byId[s[field]] = s;
  });
  return byId;
};

export const getByField = (collection, property) => {
  var i = 0,
    val,
    index,
    values = [],
    result = [];
  if (collection.length > 0) {
    for (; i < collection.length; i++) {
      val = collection[i][property];
      index = values.indexOf(val);
      if (index > -1) result[index].push(collection[i]);
      else {
        values.push(val);
        result.push([collection[i]]);
      }
    }
  }
  return result;
};

export const getFlatenedSchema = (
  schema,
  data,
  filterArray,
  avoidSectionNameFilter,
  avoidStaticFields
) => {
  const flatenedSchema = [];
  schema
    ?.filter(
      (item) =>
        item?.type !== "multiSelectGoal" &&
        item?.type !== "multiSelectChildGoal" &&
        item?.type !== "multiSelect" &&
        item?.type !== "templateGoal"
    )
    ?.filter(
      (item) =>
        (!avoidSectionNameFilter ? item?.section_name : true) &&
        (avoidStaticFields
          ? item?.key !== "title" &&
          item?.key !== "image" &&
          item?.key !== "banner"
          : true)
    )
    ?.forEach((item) => {
      flatenedSchema.push(
        Object.assign({}, item, {
          section_name: item?.section_name
            ? item?.section_name
            : `Goal Details`,
          section_order: item?.section_order ? item?.section_order : 0,
        })
      );
    });

  const finalArray = [];
  flatenedSchema?.forEach((item, index) => {
    if (
      item &&
      (!item?.important && item?.type !== "boolean" && item?.type !== "number" && item?.type !== "income" && item?.type !== "securityDeposit" && item?.type !== "checkList" ? (Array.isArray(data?.[item?.key]) ? data?.[item?.key]?.length : data?.[item?.key]) : true) &&
      (filterArray && filterArray?.length
        ? !filterArray?.includes(item?.type)
        : true)
    ) {
      if (item?.type === "number") {
        finalArray.push(
          Object.assign({}, item, {
            value: data[item?.key] || data[item?.key] === 0 ? Number(data[item?.key]) : data[item?.key]
          })
        );
      }
      else if (item?.type === "profile") {
        finalArray.push(
          Object.assign({}, item, {
            value: Array.isArray(data[item?.key]) ? data[item?.key]?.filter((item) => item) : data[item?.key],
            _group_value: data[`_groups_${item?.key}`]
          })
        );
      }
      else if (item?.type === "client") {
        finalArray.push(
          Object.assign({}, item, {
            value: Array.isArray(data[item?.key]) ? data[item?.key]?.filter((item) => item) : data[item?.key],
          })
        );
      }
      else if (item?.type === "vendor") {
        finalArray.push(
          Object.assign({}, item, {
            value: Array.isArray(data[item?.key]) ? data[item?.key]?.filter((item) => item) : data[item?.key],
          })
        );
      }
      else if (item?.type === "location") {
        finalArray.push(
          Object.assign({}, item, {
            value: Array.isArray(data[item?.key]) ? (data[item?.key]?.length ? data[item?.key]?.filter((item) => item) : []) : data[item?.key],
          })
        );
      }
      else if (item?.type === "income") {
        let finalValue = null
        // if (item?.type === "income" && item?.relational_income && item?.income_parent_tag && item?.income_parent_field) {
        //   const parentIncomeSchema = parentGoalsSchema?.filter(sch => sch?._id === item?.income_parent_field && sch?.tag === item?.income_parent_tag)?.[0]
        //   const finalGoal = parentGoals?.filter(pg => pg?.[parentIncomeSchema?.key])?.[0]
        //   finalValue = finalGoal?.[parentIncomeSchema?.key]
        // }
        // else {
        finalValue = Array.isArray(data[item?.key]) ? (data[item?.key]?.length ? data[item?.key]?.filter((item) => item) : []) : data[item?.key] ? data[item?.key] : null
        // }
        if (finalValue || item?.important) {
          finalArray.push(
            Object.assign({}, item, {
              _parentGoalValueAssigned: finalValue ? true : false,
              value: finalValue,
            })
          );
        }
      } else if (item?.type === "securityDeposit") {
        finalArray.push(
          Object.assign({}, item, {
            value: Array.isArray(data[item?.key]) ? (data[item?.key]?.length ? data[item?.key]?.filter((item) => item) : []) : data[item?.key] ? data[item?.key] : [],
          })
        );
      } else if (item?.type === "checkList") {
        finalArray.push(
          Object.assign({}, item, {
            value: Array.isArray(data[item?.key]) ? (data[item?.key]?.length ? data[item?.key]?.filter((item) => item) : []) : data[item?.key],
          })
        );
      }
      else if (item?.type === "expense") {
        finalArray.push(
          Object.assign({}, item, {
            value: Array.isArray(data[item?.key]) ? (data[item?.key]?.length ? data[item?.key]?.filter((item) => item) : []) : data[item?.key],
          })
        );
      }
      else if (item?.type === "status") {
        const optKeys = item?.options?.map((opt) => opt?.key)
        let finalStatus = data?.[item?.key]
        if (!optKeys?.includes(data?.[item?.key])) {
          finalStatus = data?.[`_public_${item?.key}`]
        }
        finalArray.push(
          Object.assign({}, item, {
            value: finalStatus
          })
        );
      }
      else {
        finalArray.push(
          Object.assign({}, item, {
            value: Array.isArray(data[item?.key]) ? data[item?.key]?.filter((item) => item) : data[item?.key],
          })
        );
      }
    }
    else if (item?.type === "createdDetails") {
      finalArray.push(
        Object.assign({}, item, {
          value: data?.created_at && data?.createdByName ? { created_at: data?.created_at, created_by: data?.createdByName } : "",
        })
      );
    }
  });

  const OrderedSchema = finalArray.sort((a, b) => {
    return a.section_order - b.section_order;
  });

  return OrderedSchema;
};

export const monthToName = {
  1: "Jan",
  2: "Feb",
  3: "Mar",
  4: "Apr",
  5: "May",
  6: "Jun",
  7: "Jul",
  8: "Aug",
  9: "Sep",
  10: "Oct",
  11: "Nov",
  12: "Dec",
};

export const OpenLink = (data) => {
  const pattern = /^((http|https|ftp):\/\/)/;
  if (!pattern.test(data)) {
    let url = "https://" + data;
    window.open(url, "_blank");
  } else {
    window.open(data, "_blank");
  }
};

export const getFormData = (files) => {
  let formData = new FormData();
  for (let i = 0; i < files.length; i++) {
    const file = files[i];

    formData.append("file", file, file.name);
  }
  return formData;
};

export const getFileUploads = (values) => {
  let filteredUploads = [];
  Object.keys(values)?.forEach((key) => {
    if (values[key]?.length && Array.isArray(values[key])) {
      values[key]?.map((item, index) => {
        if (item?.preview) {
          let fileExt = item?.name?.split(".")?.pop();
          filteredUploads.push({
            file: item,
            name: `${key}_${index}.${fileExt}`,
          });
        }
        else if (item?.uploadUrl && Array.isArray(item?.uploadUrl) && item?.uploadUrl?.length) {
          item?.uploadUrl?.forEach((itemKey, ind) => {
            if (itemKey?.preview) {
              // let fileExt = itemKey?.name?.split(".")?.pop();
              filteredUploads.push({
                file: itemKey,
                name: `${key}|${index}_${ind}|${itemKey?.name}`,
              });
            }
            else if (itemKey?.url?.preview) {
              let fileExt = itemKey?.url?.name?.split(".")?.pop();
              filteredUploads.push({
                file: itemKey?.url,
                name: `${key}|${index}_${ind}|${itemKey?.name}`,
              });
            }
          })
        }
        else {
          Object.keys(item)?.forEach((itemKey) => {
            if (item[itemKey]?.preview) {
              let fileExt = item[itemKey]?.name?.split(".")?.pop();
              filteredUploads.push({
                file: item[itemKey],
                name: `${key}|${itemKey}_${index}.${fileExt}`,
              });
            }
          });
        }
      });
    } else if (values[key]?.preview) {
      let fileExt = values[key]?.name?.split(".")?.pop();
      filteredUploads.push({
        file: values[key],
        name: `${key}_${0}.${fileExt}`,
      });
    } else if (values[key]?.uploadUrl) {
      if (values[key]?.uploadUrl?.preview) {
        let fileExt = values[key]?.uploadUrl?.name?.split(".")?.pop();
        filteredUploads.push({
          file: values[key].uploadUrl,
          name: `${key}_${0}|${values[key]?.uploadUrl?.url?.name}`,
        });
      }
      else if (values[key]?.uploadUrl?.url?.preview) {
        let fileExt = values[key]?.uploadUrl?.url?.name?.split(".")?.pop();
        filteredUploads.push({
          file: values[key].uploadUrl?.url,
          name: `${key}_${0}|${values[key]?.uploadUrl?.url?.name}`,
        });
      }
    }
  });
  return filteredUploads;
};

export const getValuesWithFileUploads = (
  fileUploadData,
  schemaArray,
  formValues
) => {
  const newfileUploadData = (
    Array.isArray(fileUploadData) ? fileUploadData : [fileUploadData]
  )?.map((item) => {
    return Object.assign({}, item, {
      filename: item?.filename
        ?.split("_", item?.filename?.split("_")?.length - 1)
        ?.join("_"),
    });
  });
  let fileByType = _.groupBy(newfileUploadData, (x) => x.filename);
  let finalUploadData = {};
  Object.keys(fileByType)?.forEach((key) => {
    const [profileKey, profileFieldKey] = key?.split("|");
    const filteredSchema = schemaArray?.filter(
      (item) => item?.key === (profileFieldKey ? profileKey : key)
    )?.[0];
    if (filteredSchema && filteredSchema?.multiple) {
      if (filteredSchema?.type === "multiSelectableMultiUpload") {
        let fileArray = fileByType[key]
        fileArray?.forEach(fileObj => {
          const nameSplit = fileObj?.name?.split("|")
          let [parentIndex, childIndex] = nameSplit?.[nameSplit?.length - 2]?.split("_")
          formValues[filteredSchema.key][parentIndex].uploadUrl[childIndex] = { url: fileObj?.publicUrl, name: nameSplit?.[nameSplit?.length - 1] }
        })
      }
      else {
        const previousUploads = formValues?.[key]?.filter((img) => !img?.preview);
        finalUploadData = Object.assign({}, finalUploadData, {
          [key]: fileByType[key]
            ?.map((file) => file?.publicUrl)
            ?.concat(previousUploads),
        });
      }
    } else {
      if (filteredSchema?.type === "selectableUpload") {
        formValues[filteredSchema.key].uploadUrl = fileByType[key]?.map(
          (file) => {
            const actualFileName = file?.name?.split("|")
            return { url: file?.publicUrl, name: actualFileName?.[actualFileName?.length - 1] }
          }
        )?.[0];
      }
      else if (filteredSchema?.type === "multiSelectableMultiUpload") {
        let fileArray = fileByType[key]
        fileArray?.forEach(fileObj => {
          const nameSplit = fileObj?.name?.split("|")
          let [parentIndex, childIndex] = nameSplit?.[nameSplit?.length - 2]?.split("_")
          formValues[filteredSchema.key][parentIndex].uploadUrl[childIndex] = { url: fileObj?.publicUrl, name: nameSplit?.[nameSplit?.length - 1] }
        })
      } else if (filteredSchema?.type === "profile") {
        fileByType[key]?.forEach((profileFile, ind) => {
          formValues[profileKey][ind][profileFieldKey] = profileFile?.publicUrl;
        });
      } else {
        finalUploadData = Object.assign({}, finalUploadData, {
          [key]: fileByType[key]?.map((file) => file?.publicUrl)?.[0],
        });
      }
    }
  });
  let finalValues = Object.assign({}, formValues, finalUploadData);
  Object.keys(finalValues)?.forEach((key) => {
    if (finalValues[key] === "") {
      delete finalValues[key];
    }
  });
  return finalValues;
};

export const dynamicFilter = (
  list,
  filterStatus,
  statusField,
  filterObj,
  parentTagId,
  module,
  listTableObj,
  statusSchemaField,
  favoriteGoals,
  isProfile
) => {
  let actionStatusKeys = statusSchemaField?.options?.filter((opt) => opt?.action_status)?.map(x => x?.key);
  let finalProducts = list?.filter((item) => {
    return (parentTagId
      ? item?.parent_goal_id?.includes(parentTagId) ||
      (module !== "client" ? item?.[`${module}_ids`]?.includes(parentTagId) : true) ||
      (module !== "vendor" ? item?.[`${module}_ids`]?.includes(parentTagId) : true) ||
      (module !== "location" ? item?.[`${module}_ids`]?.includes(parentTagId) : true)
      : true)
  })
    ?.map((item) => item);
  if (Object.keys(filterObj)?.length) {
    Object.keys(filterObj)?.map((key) => {
      const [type, schemaKey] = key.split("|");
      finalProducts = finalProducts?.filter((product) => {
        if (type === "search") {
          let fieldTitle = isProfile ? `${product?.first_name}${product?.last_name ? product?.last_name : ""}` : product?.[schemaKey];
          return fieldTitle
            ?.toLowerCase()
            ?.includes(filterObj?.[key]?.toLowerCase());
        } else if (type === "vendor") {
          const vendorTitles = product?.[`${schemaKey}`]?.map(opt => opt?.title)?.filter((opt) => opt?.toLowerCase()?.includes(filterObj?.[key]?.toLowerCase()))
          return filterObj?.[key]?.toLowerCase() ? vendorTitles && vendorTitles?.length > 0 : true
        } else if (type === "location") {
          if (!Array.isArray(filterObj?.[key]) && typeof filterObj?.[key] === "string") {
            const locationTitles = product?.[`${schemaKey}`]?.map(opt => opt?.title)?.filter((opt) => opt?.toLowerCase()?.includes(filterObj?.[key]?.toLowerCase()))
            return filterObj?.[key]?.toLowerCase() ? locationTitles && locationTitles?.length > 0 : true
          }
          else {
            const productLocationIds = product?.[`${schemaKey}`] ? product?.[`${schemaKey}`]?.map((item) => item?._id) : [];
            const filterLocationIds = filterObj?.[key]?.map((item) => item?._id)
            return filterObj[key] && filterObj[key]?.length
              ? _.intersection(productLocationIds, filterLocationIds)
                ?.length > 0
              : true;
          }
        } else if (type === "locationByField") {
          const locationVal = product?.[`${schemaKey}`]?.map(opt => opt?.value)
          if (!Array.isArray(filterObj?.[key]) && typeof filterObj?.[key] === "string") {
            const locationTitles = product?.[`${schemaKey}`]?.map(opt => opt?.value)?.filter((opt) => opt?.toLowerCase()?.includes(filterObj?.[key]?.toLowerCase()))
            return filterObj?.[key]?.toLowerCase() ? locationTitles && locationTitles?.length > 0 : true
          }
          else{
            const filterLocationVals = filterObj?.[key]?.map((item) => item?.value)
            return filterObj[key] && filterObj[key]?.length
              ? _.intersection(locationVal, filterLocationVals)
                ?.length > 0
              : true;
          }
        } else if (type === "client") {
          if (!Array.isArray(filterObj?.[key]) && typeof filterObj?.[key] === "string") {
            const clientTitles = product?.[`${schemaKey}`]?.map(opt => opt?.title)?.filter((opt) => opt?.toLowerCase()?.includes(filterObj?.[key]?.toLowerCase()))
            return filterObj?.[key]?.toLowerCase() ? clientTitles && clientTitles?.length > 0 : true
          }
          else {
            const productClientIds = product?.[`${schemaKey}`] ? product?.[`${schemaKey}`]?.map((item) => item?._id) : [];
            const filterClientIds = filterObj?.[key]?.map((item) => item?._id)
            return filterObj[key] && filterObj[key]?.length
              ? _.intersection(productClientIds, filterClientIds)
                ?.length > 0
              : true;
          }
        }
        else if (type === "profile") {
          const productProfileIds = product?.[`${schemaKey}`]?.map((item) => item?._id)
          const filterProfileIds = filterObj?.[key]?.map((item) => item?._id)
          return filterObj[key] && filterObj[key]?.length
            ? _.intersection(productProfileIds, filterProfileIds)
              ?.length > 0
            : true;
        } else if (type === "createdDetails") {
          const filterProfileNames = filterObj?.[key]?.map((item) => item?.first_name + (item?.last_name ? (" " + item?.last_name) : ""))
          if (filterProfileNames?.length > 0) {
            return filterProfileNames?.filter(proName => product?.["createdByName"]?.includes(proName))?.length
          } else {
            return true;
          }
        } else if (type === "input") {
          return product?.[schemaKey]
            ?.toLowerCase()
            ?.includes(filterObj?.[key]?.toLowerCase());
        } else if (type === "textArea") {
          return Boolean(filterObj?.[key]) ? product?.[schemaKey]?.toLowerCase()?.includes(filterObj?.[key]?.toLowerCase()) : true

        } else if (type === "number") {
          return filterObj?.[key] ? product?.[schemaKey]?.toString()?.includes(filterObj?.[key]) : true;
        } else if (type === "date" && schemaKey === "lastUpdatedOn") {
          let lastUpdatedStartDate = new Date(filterObj?.[`date|lastUpdatedOn`]?.start);
          let lastUpdatedEndDate = new Date(filterObj?.[`date|lastUpdatedOn`]?.end);
          let goalLastUpdatedDate = new Date(product?.updated_at);
          if (
            lastUpdatedStartDate &&
            lastUpdatedEndDate &&
            moment(lastUpdatedStartDate).isSameOrBefore(
              moment(lastUpdatedEndDate)
            )
          ) {
            lastUpdatedStartDate.setHours(0, 0, 0, 0);
            lastUpdatedEndDate.setHours(0, 0, 0, 0);
            goalLastUpdatedDate.setHours(0, 0, 0, 0);
            return (
              moment(lastUpdatedStartDate).isSameOrBefore(
                moment(goalLastUpdatedDate)
              ) &&
              moment(lastUpdatedEndDate).isSameOrAfter(
                moment(goalLastUpdatedDate)
              )
            );
          }
        }
        else if (type === "date") {
          if (
            filterObj[key]?.startDate &&
            filterObj[key]?.endDate &&
            moment(filterObj[key]?.startDate).isSameOrBefore(
              moment(filterObj[key]?.endDate)
            )
          ) {
            let startDate = new Date(filterObj[key]?.startDate);
            let endDate = new Date(filterObj[key]?.endDate);
            startDate.setHours(0, 0, 0, 0);
            endDate.setHours(0, 0, 0, 0);

            if (schemaKey === "startEndDate") {
              let productStartDate = new Date(product?.start_date);
              let productEndDate = new Date(product?.end_date);
              productStartDate.setHours(0, 0, 0, 0);
              productEndDate.setHours(0, 0, 0, 0);
              return (
                moment(startDate).isSameOrBefore(
                  moment(productStartDate)
                ) &&
                moment(endDate).isSameOrAfter(
                  moment(productEndDate)
                )
              );
            }
            let productDate = new Date(product?.[schemaKey]);
            productDate.setHours(0, 0, 0, 0);
            return (
              moment(startDate).isSameOrBefore(
                moment(productDate)
              ) &&
              moment(endDate).isSameOrAfter(
                moment(productDate)
              )
            );
          } else {
            if (schemaKey === "startEndDate") {
              return (
                (filterObj[key]?.startDate
                  ? moment(filterObj[key]?.startDate).isSameOrBefore(
                    moment(product?.start_date)
                  )
                  : true) &&
                (filterObj[key]?.endDate
                  ? moment(filterObj[key]?.endDate).isSameOrAfter(
                    moment(product?.end_date)
                  )
                  : true)
              );
            }
          }
          return true;
        } else if (type === "dateTime") {
          if (
            filterObj[key]?.startDate &&
            filterObj[key]?.endDate &&
            moment(filterObj[key]?.startDate).isSameOrBefore(
              moment(filterObj[key]?.endDate)
            )
          ) {
            return (
              moment(filterObj[key]?.startDate).isSameOrBefore(
                moment(product?.[schemaKey])
              ) &&
              moment(filterObj[key]?.endDate).isSameOrAfter(
                moment(product?.[schemaKey])
              )
            );
          }
          return true;
        } else if (type === "securityDeposit") {
          let listSDReturnDates = product?.[schemaKey]?.map((x) => x?.return_date);
          let flag = false;
          if (!filterObj[key]?.return_date) flag = true;
          if (listSDReturnDates?.length > 0) {
            flag = listSDReturnDates?.some((d) => {
              return moment(d).isSameOrBefore(filterObj[key]?.return_date)
            })
          }
          return flag;
        } else if (type === "select") {
          return filterObj[key] && filterObj[key]?.length
            ? filterObj[key]?.includes(product?.[schemaKey])
            : true;
        } else if (type === "boolean") {
          if (filterObj[key] === null || filterObj[key]?.id === null){
            return true
          }
          return product?.[schemaKey] === filterObj[key]?.id;
        } else if (type === "favorite") {
          const localFavIdList = Object.keys(favoriteGoals)?.filter(x => favoriteGoals?.[x]);
          return filterObj[key] ? localFavIdList?.includes(product?._id) : true
        } else if (type === "multiSelectGoal") {
          return filterObj[key] && filterObj[key]?.length
            ? _.intersection(product?.["parent_goal_id"], filterObj[key])
              ?.length > 0
            : true;
        } else if (type === "multiSelectChildGoal") {
          const childids = product?.[schemaKey]?.map(val => val?.id)
          return filterObj[key] && filterObj[key]?.length
            ? _.intersection(childids, filterObj[key])
              ?.length > 0
            : true;
        } else if (type === "items") {
          const childids = product?.[schemaKey]?.map(val => val?.item_key);
          return filterObj[key] && filterObj[key]?.length
            ? _.intersection(childids, filterObj[key])
              ?.length > 0
            : true
        } else if (type === "status") {
          const arrayOfOptionKeys = filterObj[key]?.map((opt) => opt?.key);
          return filterObj[key] && filterObj[key]?.length ? arrayOfOptionKeys?.includes(product?.[schemaKey]) : true;
        } else if (type === "typableMultiSelectText") {
          return filterObj[key] && filterObj[key]?.length
            ? _.intersection(product?.[schemaKey], filterObj[key])
              ?.length > 0
            : true;
        } else if (type === "typableSelectText") {
          return filterObj[key]
            ? filterObj[key] === product?.[schemaKey]
            : true;
        } else if (type === "multiSelect") {
          return filterObj[key] && filterObj[key]?.length
            ? _.intersection(product?.["product_ids"], filterObj[key])?.length >
            0 ||
            _.intersection(product?.["event_ids"], filterObj[key])?.length >
            0
            : true;
        }
      });
    });
  }
  let finalProductsWithoutTabFilter = finalProducts
  if (filterStatus && filterStatus !== "All") {
    finalProducts = finalProducts?.filter((item) => {
      return (
        item[statusField?.key] ===
        statusField?.options?.filter((opt) => opt?.name === filterStatus)?.[0]
          ?.key
      );
    });
  }
  else if (filterStatus === "All") {
    finalProducts = finalProducts?.filter((item) => {
      const isHidden = actionStatusKeys?.includes(item?.[statusSchemaField?.key]);
      return !isHidden
    })
  }

  if (listTableObj && Object.keys(listTableObj)?.length) {
    const filteredProductIds = finalProducts?.map((item) => item?._id);
    let finalObj = {};
    Object.keys(listTableObj)?.forEach((key) => {
      if (filteredProductIds?.includes(key)) {
        finalObj = Object.assign({}, finalObj, {
          [key]: listTableObj[key],
        });
      }
    });
    return {
      finalArray: finalProducts,
      finalTableObj: finalObj,
      finalProductsWithoutTabFilter
    };
  }
  return finalProducts;
};

export const applyFilter = ({ inputData, comparator, orderBy }) => {
  const stabilizedThis = inputData?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    let order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis?.map((el) => el[0]);
  return inputData;
}

export const applyFilterCheckbox = ({ inputData, comparator, orderBy, schema, order }) => {
  let filterType = schema?.filter(x => x?.key === orderBy)?.[0]?.type
  let tempArray = []
  let UnenteredDateArray = []

  if (filterType === "date") {
    tempArray = inputData?.length > 0 ? inputData?.filter(x => x?.[orderBy]) : []
    UnenteredDateArray = inputData?.length > 0 ? inputData?.filter(x => !x?.[orderBy]) : []
  } else {
    tempArray = inputData?.length > 0 ? inputData : []
    UnenteredDateArray = []
  }

  const stabilizedThis = tempArray?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    if (filterType === "boolean") {
      return order === "asc" ? a[0] ? -1 : 1 : a[0] ? 1 : -1
    } else {
      let order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    }
  });

  inputData = stabilizedThis?.map((el) => el[0]);
  return [...(inputData?.length ? inputData : []), ...(UnenteredDateArray?.length ? UnenteredDateArray : [])];
}

export const applySortFilter = ({
  tableLabels,
  tableData,
  comparator,
  moduleName,
}) => {
  let data = [];
  Object.keys(tableData).forEach((el, index) => {
    let temp = {};
    tableLabels?.forEach((header) => {
      if (header.id === moduleName) {
        const filteredData = tableData[el]?.filter(
          (item) => item?.key === "title"
        )?.[0];
        temp = Object.assign({}, temp, {
          [header.id]:
            typeof filteredData?.value === "string"
              ? filteredData?.value.trim().toLowerCase()
              : filteredData?.value,
        });
      } else {
        const filteredData = tableData[el]?.filter(
          (item) => item?.key === header?.id
        )?.[0];
        if (filteredData?.type === "money") {
          temp = Object.assign({}, temp, {
            [header.id]: filteredData?.value?.amount
              ? filteredData?.value?.amount
              : 0,
          });
        } else if (
          filteredData?.type === "date" ||
          filteredData?.type === "dateTime"
        ) {
          temp = Object.assign({}, temp, {
            [header.id]: filteredData?.value ? moment(filteredData?.value) : 0,
          });
        } else if (filteredData?.type === "multiSelectGoal") {
          temp = Object.assign({}, temp, {
            [header.id]: filteredData?.value ? filteredData?.value?.length : 0,
          });
        }
        else if (filteredData?.type === "array") {
          temp = Object.assign({}, temp, {
            [header.id]: filteredData?.value
              ? filteredData?.value?.length
              : 0,
          });
        }
        else if (filteredData?.type === "typableMultiSelectText") {
          temp = Object.assign({}, temp, {
            [header.id]: filteredData?.value
              ? filteredData?.value?.length
              : 0,
          });
        }
        else if (filteredData?.type === "multiSelect") {
          temp = Object.assign({}, temp, {
            [header.id]: filteredData?.value ? filteredData?.value?.length : 0,
          });
        }
        else if (filteredData?.type === "profile") {
          temp = Object.assign({}, temp, {
            [header.id]:
              filteredData?.value?.[0]?.first_name &&
                filteredData?.value?.[0]?.last_name
                ? (
                  filteredData?.value?.[0]?.first_name +
                  " " +
                  filteredData?.value?.[0]?.last_name
                )
                  .trim()
                  .toLowerCase()
                : 0,
          });
        } else {
          temp = Object.assign({}, temp, {
            [header.id]:
              typeof filteredData?.value === "string"
                ? filteredData?.value.trim().toLowerCase()
                : filteredData?.value ? filteredData?.value : "",
          });
        }
      }
    });
    temp = Object.assign({}, temp, {
      module_id: el,
    });
    data.push(temp);
  });

  const stabilizedThis = data.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  let finalData = {};
  stabilizedThis?.forEach((item) => {
    finalData = Object.assign({}, finalData, {
      [item[0]["module_id"]]: tableData[item[0]["module_id"]],
    });
  });

  return finalData;
};

export const getIconfromAllItems = (items) => {
  let finalIcon = "ic:round-account-box";
  if (items && items?.length) {
    items?.every((item) => {
      if (item?.section_icon) {
        finalIcon = item?.section_icon;
        return false;
      }
    });
  }
  return finalIcon;
};

export const getWindowSize = () => {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
};

export const getFieldValueByType = (fieldSchema, data, schema, productList) => {
  if (
    fieldSchema?.type === "input" ||
    fieldSchema?.type === "email" ||
    fieldSchema?.type === "phone" ||
    fieldSchema?.type === "url" ||
    fieldSchema?.type === "textArea" ||
    fieldSchema?.type === "typableSelectText"
  ) {
    return data?.[fieldSchema?.key] ? data?.[fieldSchema?.key] : "";
  } else if (
    fieldSchema?.type === "select" ||
    fieldSchema?.type === "radio" ||
    fieldSchema?.type === "checkbox" ||
    fieldSchema?.type === "status"
  ) {
    const options = (fieldSchema?.options ? fieldSchema?.options : [])?.filter(
      (item) => item?.key === data?.[fieldSchema?.key]
    )?.[0];
    return options ? options?.name : "";
  } else if (fieldSchema?.type === "boolean") {
    return data?.[fieldSchema?.key] ? "Yes" : "No";
  } else if (fieldSchema?.type === "date") {
    return data?.[fieldSchema?.key]
      ? moment(data?.[fieldSchema?.key]).format("MM-DD-YYYY")
      : "";
  } else if (fieldSchema?.type === "dateTime") {
    return data?.[fieldSchema?.key]
      ? moment(data?.[fieldSchema?.key]).format("MM-DD-YYYY HH:mm")
      : "";
  } else if (fieldSchema?.type === "money") {
    return data?.[fieldSchema?.key] && data?.[fieldSchema?.key]?.amount
      ? `$${data?.[fieldSchema?.key]?.amount}`
      : "";
  } else if (fieldSchema?.type === "array") {
    return data?.[fieldSchema?.key]
      ? data?.[fieldSchema?.key]?.filter((item) => item)?.join(", ")
      : "";
  } else if (fieldSchema?.type === "currency") {
    return data?.[fieldSchema?.key] ? `$${data?.[fieldSchema?.key]}` : "";
  } else if (fieldSchema?.type === "video") {
    return data?.[fieldSchema?.key]
      ? `${data?.[fieldSchema?.key]?.videoTitle}`
      : "";
  } else if (fieldSchema?.type === "selectableUpload") {
    const options = (fieldSchema?.options ? fieldSchema?.options : [])?.filter(
      (item) => item?.key === data?.[fieldSchema?.key]?.uploadType
    )?.[0];
    return options ? options?.name : "";
  } else if (fieldSchema?.type === "upload") {
    return fieldSchema?.multiple
      ? `${data?.[fieldSchema?.key]?.length} Files`
      : "1 File";
  } else if (fieldSchema?.type === "location") {
    const finalValue = data?.[fieldSchema?.key] && data?.[fieldSchema?.key]?.map((item) => fieldSchema?.location_fields?.map((field) => item?.[field?.key] && (typeof item?.[field?.key] === "string") ? item?.[field?.key] : "")?.filter(val => val)?.join(", "))
      ?.filter((item) => item)
      ?.join(" | ");
    return finalValue ? finalValue : "";
  } else if (fieldSchema?.type === "client") {
    const finalValue = data?.[fieldSchema?.key]?.map((item) => fieldSchema?.client_fields?.map((field) => item?.[field?.key] && (typeof item?.[field?.key] === "string") ? item?.[field?.key] : "")?.filter(val => val)?.join(", "))
      ?.filter((item) => item)
      ?.join(" | ");
    return finalValue ? finalValue : "";
  } else if (fieldSchema?.type === "multiSelect") {
    const finalValue = data?.["_products_data"]?.map((item) => item?.title)
      ?.filter((item) => item)
      ?.join(", ");
    return finalValue ? finalValue : "";
  } else if (fieldSchema?.type === "profile") {
    const finalValue = data?.[fieldSchema?.key]
      ?.map(
        (item) =>
          `${item?.last_name ? item?.last_name : item?.first_name ? item?.first_name : item?.email ? item?.email : ""}`
      )
      ?.filter((item) => item)
      ?.join(", ");
    return finalValue ? finalValue : "";
  }
  return "";
};

export const validateNewProfile = (
  field,
  value,
  profileFields,
  dialogValue,
  setProfileDialogErrorObj,
  handleClose
) => {
  let finalValue = {}
  let noOfFieldsValid = 0;
  let erroObj = {};
  profileFields?.forEach((item) => {
    if (item?.required) {
      if (dialogValue[item?.key]) {
        if (item?.type === "email") {
          const mailformat =
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (dialogValue?.[item?.key]?.match(mailformat)) {
            noOfFieldsValid = noOfFieldsValid + 1;
          } else {
            erroObj = Object.assign({}, erroObj, {
              [item?.key]: "Please enter a valid email",
            });
          }
        }
        if (item?.type === "profile") {
          if (dialogValue?.[item?.key] && Object.keys(dialogValue?.[item?.key])?.length) {
            noOfFieldsValid = noOfFieldsValid + 1;
          } else {
            erroObj = Object.assign({}, erroObj, {
              [item?.key]: `Please select a ${field?.name}`,
            });
          }
        }
        if (item?.type === "client") {
          if (dialogValue?.[item?.key] && Object.keys(dialogValue?.[item?.key])?.length) {
            noOfFieldsValid = noOfFieldsValid + 1;
          } else {
            erroObj = Object.assign({}, erroObj, {
              [item?.key]: `Please select a ${field?.name}`,
            });
          }
        }
        if (item?.type === "phone") {
          if (
            /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/.test(
              dialogValue[item?.key]?.phone
            )
          ) {
            noOfFieldsValid = noOfFieldsValid + 1;
            finalValue[item.key] = (dialogValue[item?.key]?.countryCode ? (dialogValue[item?.key]?.countryCode + "-") : "") + (dialogValue[item?.key]?.phone ? dialogValue[item?.key]?.phone : "") + (dialogValue[item?.key]?.ext ? ("-" + dialogValue[item?.key]?.ext) : "")
          } else {
            erroObj = Object.assign({}, erroObj, {
              [item?.key]: "Please enter a valid Phone number",
            });
          }
        }
        if (item?.type === "date") {
          if (moment(dialogValue[item?.key], "MM/DD/YYYY", true).isValid()) {
            noOfFieldsValid = noOfFieldsValid + 1;
          } else {
            erroObj = Object.assign({}, erroObj, {
              [item?.key]: "Please enter a valid date",
            });
          }
        } else {
          noOfFieldsValid = noOfFieldsValid + 1;
        }
      } else {
        erroObj = Object.assign({}, erroObj, {
          [item?.key]: `Please enter ${item?.name}`,
        });
      }
    } else {
      if (dialogValue[item?.key]) {
        if (item?.type === "email") {
          if (dialogValue?.[item?.key]) {
            const mailformat =
              /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (dialogValue?.[item?.key]?.match(mailformat)) {
              noOfFieldsValid = noOfFieldsValid + 1;
            } else {
              erroObj = Object.assign({}, erroObj, {
                [item?.key]: "Please enter a valid email",
              });
            }
          } else {
            noOfFieldsValid = noOfFieldsValid + 1;
          }
        }
        if (item?.type === "phone") {
          if (dialogValue[item?.key]?.phone) {
            if (
              /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/.test(
                dialogValue[item?.key]?.phone
              )
            ) {
              noOfFieldsValid = noOfFieldsValid + 1;
              finalValue[item.key] = (dialogValue[item?.key]?.countryCode ? (dialogValue[item?.key]?.countryCode + "-") : "") + (dialogValue[item?.key]?.phone ? dialogValue[item?.key]?.phone : "") + (dialogValue[item?.key]?.ext ? ("-" + dialogValue[item?.key]?.ext) : "")
            } else {
              erroObj = Object.assign({}, erroObj, {
                [item?.key]: "Please enter a valid Phone number",
              });
            }
          }
          else {
            noOfFieldsValid = noOfFieldsValid + 1;
            finalValue[item.key] = (dialogValue[item?.key]?.countryCode ? (dialogValue[item?.key]?.countryCode + "-") : "") + (dialogValue[item?.key]?.phone ? dialogValue[item?.key]?.phone : "") + (dialogValue[item?.key]?.ext ? ("-" + dialogValue[item?.key]?.ext) : "")
          }
        }
        if (item?.type === "date") {
          if (dialogValue[item?.key]) {
            if (moment(dialogValue[item?.key], "MM/DD/YYYY", true).isValid()) {
              noOfFieldsValid = noOfFieldsValid + 1;
            } else {
              erroObj = Object.assign({}, erroObj, {
                [item?.key]: "Please enter a valid date",
              });
            }
          }
          else {
            noOfFieldsValid = noOfFieldsValid + 1;
          }
        } else {
          noOfFieldsValid = noOfFieldsValid + 1;
        }
      }
    }
  });
  const finalObj = Object.assign({}, value[value?.length - 1], finalValue)
  value[value?.length - 1] = finalObj
  if (erroObj && Object.keys(erroObj)?.length) {
    setProfileDialogErrorObj(erroObj);
  } else {
    field.onChange(value);
    handleClose(finalObj);
  }
};

export function fShortenNumber(number) {
  const format = number ? numeral(number).format('0.00a') : '';

  return format;
}

export const getCsvValuesByType = (schema) => {
  if (schema?.type === "profile") {
    return schema?.value?.length ? schema?.value?.map(profile => profile?.first_name + (profile?.last_name ? (" " + profile?.last_name) : "") + (profile?.email ? (" - " + profile?.email) : ""))?.join(" | ") : ""
  } else if (schema?.type === "createdDetails") {
    // return schema?.value?.length ? schema?.value?.map(profile => profile?.first_name + (profile?.last_name ? (" " + profile?.last_name) : "") + (profile?.email ? (" - " + profile?.email) : ""))?.join(" | ") : ""
  }
  else if (schema?.type === "video") {
    return schema?.value?.videoTitle + " - " + schema?.value?.videoAddress
  }
  else if (schema?.type === "checkbox" || schema?.type === "array" || schema?.type === "typableMultiSelectText" || schema?.type === "multiSelect") {
    return schema?.value?.join(" | ")
  }
  else if (schema?.type === "money") {
    return schema?.value?.amount
  }
  else if (schema?.type === "selectableUpload") {
    return schema?.value?.uploadType
  }
  else if (schema?.type === "multiSelectableMultiUpload") {
    return schema?.value?.map(item => `${item?.uploadType} - ${item?.uploadUrl?.length} files`)?.join(" | ")
  }
  else if (schema?.type === "income") {
    return schema?.value?.length + " items"
  }
  else if (schema?.type === "expense") {
    return schema?.value?.length + " items"
  }
  else if (schema?.type === "uploadDownload") {
    return schema?.value?.join(" | ")
  }
  else if (schema?.type === "location" || schema?.type === "client"|| schema?.type === "multiSelectGoal" || schema?.type === "multiSelectChildGoal") {
    return schema?.value?.length ? schema?.value?.map(item => item?.title)?.join(" | ") : ""
  }
  return schema?.value ? schema?.value : ""
}


export const invoiceStatus = {
  "Draft": "draft",
  "Published": "published",
  "Partially Paid": "partiallyPaid",
  "Paid": "paid",
  "Cancelled": "cancelled",
  "Partially Published":"partiallyPublished"
}

export const invoiceStatusLabels = {
  'draft': 'Draft',
  'published': 'Published',
  'cancelled': 'Cancelled',
  'paid': 'Paid',
  'refunded': 'Refunded',
  'partiallyPaid': 'Partially Paid',
  'partiallyPublished': 'Partially Published'
}

export const donationStatus = {
  "Draft": "draft",
  "Published": "published",
  "Subscribed": "subscribed",
  "Cancelled": "cancelled",
  "Paid": "paid"
}
export const donationStatusLabels = {
  "draft": "Draft",
  "published": "Published",
  "subscribed": "Subscribed",
  "cancelled": "Cancelled",
  "paid": "Paid"
}

export const vendorInvoiceStatus = {
  "Quotes": "draft",
  "Published": "published",
  "Partially Invoiced": "partiallyInvoiced",
  "Invoiced": "invoiced",
  "Partially Paid": "partiallyPaid",
  "Paid": "paid",
  "Cancelled": "cancelled",
}

export const vendorInvoiceStatusLabels = {
  'draft': 'Quotes',
  'published': 'Published',
  'cancelled': 'Cancelled',
  'paid': 'Paid',
  'partiallyInvoiced': 'Partially Invoiced',
  'invoiced': 'Invoiced',
  'partiallyPaid': 'Partially Paid'
}

export const checkEditAllowed = (emailBurstData) => {
  if (emailBurstData && emailBurstData?._id) {
    if (emailBurstData?.send_details?.type === "scheduled") {
      if (emailBurstData?.status === "draft") {
        return true
      }
      else if (emailBurstData?.status === "ready" || emailBurstData?.status === "inProgress") {
        return true
      }
      else if (emailBurstData?.status === "completed") {
        return false
      }
    }
    else {
      if (emailBurstData?.status === "draft") {
        return true
      }
      else {
        return false
      }
    }
    return false
  }
  return true
}

export const handleNavigate = (selectedNotification, navigate, profileType) => {
  if (selectedNotification?.module_type === "goal") {
    navigate(profileType !== "admin" ? `/me/goals/tag/${selectedNotification?.tag}/view/${selectedNotification?.module_id}` : `/goals/tag/${selectedNotification?.tag}/view/${selectedNotification?.module_id}`)
  }
  else if (selectedNotification?.module_type === "product") {
    navigate(profileType !== "admin" ? `/me/products/view/${selectedNotification?.module_id}` : '/products/view/${selectedNotification?.module_id}')
  }
  else if (selectedNotification?.module_type === "contract") {
    navigate(profileType !== "admin" ? `/me/contract/${selectedNotification?.module_id}` : `/contract/${selectedNotification?.module_id}`)
  }
  else if (selectedNotification?.module_type === "client") {
    navigate(profileType !== "admin" ? `/me/clients/view/${selectedNotification?.module_id}` : `/clients/view/${selectedNotification?.module_id}`)
  }
  else if (selectedNotification?.module_type === "vendor") {
    navigate(profileType !== "admin" ? `/me/vendors/view/${selectedNotification?.module_id}` : `/vendors/view/${selectedNotification?.module_id}`)
  }
  else if (selectedNotification?.module_type === "invoice") {
    navigate(profileType !== "admin" ? `/me/goal/${selectedNotification?.goal_id}/tag/${selectedNotification?.tag_id}/invoice/${selectedNotification?.module_id}` : `goal/${selectedNotification?.goal_id}/tag/${selectedNotification?.tag_id}/invoice/${selectedNotification?.module_id}`)
  }
  else {
    navigate('/')
  }
};

const escapeRegExp = (string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}
export const replaceAll = (str, find, replace) => {
  return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
}

export const isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export const isOverlappingDates = (start1, end1, start2, end2) => {
  return (start1 < end2 && start2 < end1);
}